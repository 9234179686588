/* eslint-env browser */

import * as Y from 'yjs'
import { WebsocketProvider } from 'y-websocket'
import { QuillBinding } from 'y-quill'
import Quill from 'quill'
import QuillCursors from 'quill-cursors'

Quill.register('modules/cursors', QuillCursors)

function loadEditor() {
    const ydoc = new Y.Doc()

    const provider = new WebsocketProvider('wss://collab.api.dev.sharewithmarvin.io/collaboration',
        document.getElementById("docname").value,
        ydoc)
    const ytext = ydoc.getText('quill')
    const editorContainer = document.createElement('div')
    editorContainer.setAttribute('id', 'editor')
    if (document.getElementById('editor') != null) {
        document.getElementsByClassName('ql-toolbar')[0].replaceWith("");
        document.getElementById('editor').replaceWith(editorContainer);
    } else {
        document.body.insertBefore(editorContainer, null)
    }
    
    const editor = new Quill(editorContainer, {
        modules: {
            cursors: true,
            toolbar: [
                [{ header: [1, 2, false] }],
                ['bold', 'italic', 'underline'],
                ['image', 'code-block']
            ],
            history: {
                userOnly: true
            }
        },
        placeholder: 'Start collaborating...',
        theme: 'snow' // or 'bubble'
    })

    const binding = new QuillBinding(ytext, editor, provider.awareness)

    // @ts-ignore
    window.example = { provider, ydoc, ytext, binding, Y }

    return provider;
}

window.addEventListener('load', () => {
    
    const provider = loadEditor();
    
    const forename =
        ['Michael', 'Christopher', 'Jessica', 'Matthew', 'Ashley','Jennifer','Joshua','Amanda','Daniel','David',
         'James','Robert','John','Joseph','Andrew','Ryan','Brandon','Jason','Justin','Sarah','William','Jonathan',
         'Stephanie','Bria','Nicole','Nicholas','Anthony','Heather','Eric','Elizabeth','Adam','Megan','Melissa',
         'Kevin','Steven','Thomas','Timothy','Christina','Kyle','Rachel'].at(Math.random() * 40);

    const surname =
        ['Chung','Chen','Melton','Hill','Puckett','Song','Hamilton','Bender','Wagner','McLaughlin',
        'McNamara','Raynor','Moon','Woodard','Desai', 'Wallace','Lawrence','Griffin','Dougherty','Powers','May',
        'Steele','Teague','Vick','Gallagher','Solomon','Walsh','Monroe','Connolly', 'Hawkins','Middleton','Goldstein',
        'Watts', 'Johnston','Weeks','Wilkerson', 'Barton', 'Walton', 'Hall', 'Ross'].at(Math.random() * 40);

    const colour = ['blue', 'red', 'yellow', 'green', 'orange', 'magenta', 'cyan', 'pink', 'grey'].at(Math.random() * 9)

    const name = forename + ' ' + surname;

    document.getElementById("username").value = name;

    // Define user name and user name
    // Check the quill-cursors package on how to change the way cursors are rendered
    provider.awareness.setLocalStateField('user', {
      name: name,
      color: colour
    });

    const connectBtn = document.getElementById('y-connect-btn')
    connectBtn.addEventListener('click', () => {
        if (provider.shouldConnect) {
            provider.disconnect()
            connectBtn.textContent = 'Connect'
        } else {
            loadEditor().connect();
            connectBtn.textContent = 'Disconnect'
        }
    })


})